import FileSystem from '../model/file-system/type/file-system';

const files: FileSystem = {
    "/package-lock.json": "file",
    "/package.json": "file",
    "/tsconfig.json": "file",
    "/infrastructure/ephemeral.yml": "file",
    "/infrastructure/permanent.yml": "file",
    "/infrastructure/production.yml": "file",
    "/src/index.css": "file",
    "/src/index.html": "file",
    "/src/index.tsx": "file",
    "/.github/workflows/deploy-branch.yml": "file",
    "/.github/workflows/deploy-production.yml": "file",
    "/src/application/index.ts": "file",
    "/src/component/code.tsx": "file",
    "/src/component/main.scss": "file",
    "/src/component/main.tsx": "file",
    "/src/component/scrollable.scss": "file",
    "/src/component/scrollable.tsx": "file",
    "/src/component/state-manager.tsx": "file",
    "/src/component/window.scss": "file",
    "/src/component/window.tsx": "file",
    "/src/directory-structure/build.js": "file",
    "/src/state/action-reducer.ts": "file",
    "/src/state/default.ts": "file",
    "/src/static/browserconfig.xml": "file",
    "/src/static/safari-pinned-tab.svg": "file",
    "/src/static/site.webmanifest": "file",
    "/src/application/change-directory/index.ts": "file",
    "/src/application/colors/index.ts": "file",
    "/src/application/confetti/circle-is-inside-rectangle.ts": "file",
    "/src/application/confetti/create-particle.ts": "file",
    "/src/application/confetti/default-state.ts": "file",
    "/src/application/confetti/displacement.ts": "file",
    "/src/application/confetti/images.ts": "file",
    "/src/application/confetti/index.ts": "file",
    "/src/application/confetti/load-images.ts": "file",
    "/src/application/confetti/projectile.ts": "file",
    "/src/application/confetti/random-between.ts": "file",
    "/src/application/confetti/render.ts": "file",
    "/src/application/file/default-state.ts": "file",
    "/src/application/file/index.ts": "file",
    "/src/application/file/root.scss": "file",
    "/src/application/file/root.tsx": "file",
    "/src/application/file/state.ts": "file",
    "/src/application/files/default-state.ts": "file",
    "/src/application/files/index.ts": "file",
    "/src/application/files/root.scss": "file",
    "/src/application/files/root.tsx": "file",
    "/src/application/files/state.ts": "file",
    "/src/application/help/index.ts": "file",
    "/src/application/intro/index.ts": "file",
    "/src/application/list-files/index.ts": "file",
    "/src/application/matrix/index.ts": "file",
    "/src/application/matrix/root.scss": "file",
    "/src/application/matrix/root.tsx": "file",
    "/src/application/print-working-directory/index.ts": "file",
    "/src/application/terminal/default-state.ts": "file",
    "/src/application/terminal/index.ts": "file",
    "/src/application/terminal/state.ts": "file",
    "/src/application/terminal/terminal.scss": "file",
    "/src/application/terminal/terminal.tsx": "file",
    "/src/application/view-file/index.ts": "file",
    "/src/model/file-system/all-directory-paths.ts": "file",
    "/src/model/file-system/all-file-paths.ts": "file",
    "/src/model/file-system/all-files.ts": "file",
    "/src/model/file-system/all-paths.ts": "file",
    "/src/model/file-system/directories-in-directory.ts": "file",
    "/src/model/file-system/directory-from-file-path.ts": "file",
    "/src/model/file-system/directory-is-in-directory.ts": "file",
    "/src/model/file-system/directory-name.ts": "file",
    "/src/model/file-system/extension-from-file-path.ts": "file",
    "/src/model/file-system/file-from-file-path.ts": "file",
    "/src/model/file-system/file-is-in-directory.ts": "file",
    "/src/model/file-system/file-name-from-file-path.ts": "file",
    "/src/model/file-system/file-name-from-file.ts": "file",
    "/src/model/file-system/files-in-directory.ts": "file",
    "/src/model/file-system/is-directory.ts": "file",
    "/src/model/file-system/is-explicit-directory.ts": "file",
    "/src/model/file-system/is-file.ts": "file",
    "/src/model/file-system/parent-directories.ts": "file",
    "/src/state/provider/browser-size.ts": "file",
    "/src/state/provider/index.ts": "file",
    "/src/state/reducer/activate-canvas.ts": "file",
    "/src/state/reducer/browser-size-change.ts": "file",
    "/src/state/reducer/change-directory.ts": "file",
    "/src/state/reducer/close-window.ts": "file",
    "/src/state/reducer/command-execution-complete.ts": "file",
    "/src/state/reducer/deactivate-canvas.ts": "file",
    "/src/state/reducer/execute-command.ts": "file",
    "/src/state/reducer/index.ts": "file",
    "/src/state/reducer/open-window.ts": "file",
    "/src/state/reducer/update-application-state.ts": "file",
    "/src/state/reducer/window-drag.ts": "file",
    "/src/state/reducer/window-touch-end.ts": "file",
    "/src/state/reducer/window-touch.ts": "file",
    "/src/state/subscriber/background-image.ts": "file",
    "/src/state/subscriber/execute-command.ts": "file",
    "/src/state/subscriber/index.ts": "file",
    "/src/state/subscriber/launch-terminal.ts": "file",
    "/src/state/subscriber/redux-dev-tools.ts": "file",
    "/src/state/type/action.ts": "file",
    "/src/state/type/dispatch.ts": "file",
    "/src/state/type/payload.ts": "file",
    "/src/state/type/provider.ts": "file",
    "/src/state/type/reducer.ts": "file",
    "/src/state/type/state.ts": "file",
    "/src/state/type/subscriber.ts": "file",
    "/src/application/confetti/type/particle.ts": "file",
    "/src/application/confetti/type/state.ts": "file",
    "/src/application/confetti/type/rectangle.ts": "file",
    "/src/application/confetti/type/point.ts": "file",
    "/src/model/application/application-toolkit.ts": "file",
    "/src/model/application/canvas-toolkit.ts": "file",
    "/src/model/application/from-list.ts": "file",
    "/src/model/application/global-state.ts": "file",
    "/src/model/file-system/type/file-system.ts": "file",
    "/src/model/file-system/type/file.ts": "file",
    "/src/model/window/type/window.ts": "file",
    "/src/model/application/type/application-list.ts": "file",
    "/src/model/application/type/application-name.ts": "file",
    "/src/model/application/type/application-toolkit.ts": "file",
    "/src/model/application/type/application.ts": "file",
    "/src/model/application/type/canvas-toolkit.ts": "file",
    "/src/model/application/type/global-state.ts": "file"
};

export default files;
